import { AlgoliaWaterbodyDetail, Waterbody, WaterbodyDetail } from '@omniafishing/core';

type WaterbodyPlaceNamable = Pick<Waterbody, 'primary_name' | 'locales'>;

export const getWaterbodyDetailName = (waterbody: WaterbodyPlaceNamable) => {
  const { primary_name, locales } = waterbody;
  return `${primary_name} (${locales.map((locale) => locale.state.abbr).join(', ')})`;
};

export const getWaterbodyPlaceName = (w: WaterbodyPlaceNamable | WaterbodyDetail) => {
  let placeName = w.primary_name;
  if (w.locales.length) {
    placeName += `, ${w.locales?.[0]?.state?.abbr}, ${w.locales?.[0]?.counties[0]}`;
  }
  return placeName;
};

export const getGenericMetaDescription = (waterbody: WaterbodyDetail) => {
  const currentSeasons = waterbody.seasons
    .filter((season) => season.active)
    .map((season) => season.display_name)
    .join('/');
  const speciesPresent = waterbody.species_known
    ? waterbody.species.map((specie) => specie.display_name).join(', ')
    : 'a variety of species';

  return `During this time of year on ${waterbody.primary_name}, fish can be found in ${currentSeasons} patterns. Anglers commonly fish for ${speciesPresent}`;
};

export const getGenericDescription = (waterbody: WaterbodyDetail) => {
  const speciesPresent = waterbody.species_known
    ? waterbody.species.map((specie) => specie.display_name).join(', ')
    : 'a variety of species';
  const currentSeasons = waterbody.seasons
    .filter((season) => season.active)
    .map((season) => season.display_name)
    .join('/');
  const locales = waterbody.locales.map(
    (locale) => `${locale.state.abbr} (${locale.counties.join(', ')})`
  );
  return `
  ${waterbody.primary_name} is a body of water in ${locales}. During this time of year, fish can be found in ${currentSeasons} patterns. Available species are ${speciesPresent}. Narrow down your tackle selection by adjusting season, water clarity, structure and technique.
  `;
};

export const waterbodyToAlgoliaWaterbodyDetailLossy = (
  waterbody: Waterbody
): AlgoliaWaterbodyDetail => {
  return {
    ...waterbody,
    // these are lost data
    average_depth_ft: null,
    activation_requested: false,
    clarity: null,
    classification: null,
    description: null,
    meta_description: null,
    meta_title: null,
    media_count: null,
    send_off_message: null,
    links: [],
    page_heading: null,
    season_groups: [],
    season_zones: [],
    seasons: [],
    secondary_title: null,
    species_known: false,
    species: [],
    send_off_title: null,
    seo_indexed: false,
    top_species: null,
    user_event_notifications: null,
    user_preferences: null,
    distance: null,
    objectID: '',
    indexed_at: 0,
    _highlightResult: {
      primary_name: {
        value: '',
      },
    },
    _geoloc: {
      lat: 0,
      lng: 0,
    },
  };
};

export const waterbodyToWaterbodyDetailLossy = (waterbody: Waterbody): WaterbodyDetail => {
  return {
    ...waterbody,
    // these are lost data
    average_depth_ft: null,
    activation_requested: false,
    clarity: null,
    classification: null,
    description: null,
    meta_description: null,
    meta_title: null,
    send_off_message: null,
    links: [],
    page_heading: null,
    season_groups: [],
    season_zones: [],
    seasons: [],
    secondary_title: null,
    species_known: false,
    species: [],
    send_off_title: null,
    seo_indexed: false,
    top_species: null,
    user_event_notifications: null,
    user_preferences: null,
    distance: null,
  };
};

export function isWaterbodyDetail(
  waterbody: Waterbody | WaterbodyDetail
): waterbody is WaterbodyDetail {
  return (
    (waterbody as WaterbodyDetail).species != null &&
    (waterbody as WaterbodyDetail).seasons != null &&
    (waterbody as WaterbodyDetail).classification != null
  );
}

export function sqKmToAcres(areaSqKm: number, precision = 2) {
  return (areaSqKm * 247.105).toFixed(precision);
}
