import type { SVGProps } from 'react';
import * as React from 'react';
const SvgGreatSpotIconImageClicked = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={51}
    height={44}
    viewBox="0 0 51 30"
    fill="none"
    {...props}
  >
    <path
      fill="#E84B25"
      stroke="#fff"
      strokeWidth={3.52}
      d="m30.9 2.276-.516-.516H13.616l-.515.516L2.276 13.1l-.516.515V30.384l.516.515L13.1 41.724l.515.516H30.384l.515-.516L41.724 30.9l.516-.515V13.616l-.516-.515z"
    />
    <g clipPath="url(#great_spot_icon_image_clicked_svg__a)">
      <path
        fill="#fff"
        d="M20.689 18.65s-1.441 4.17-.945 7.882l-1.512-.82s-1.795 3.134 1.063 3.351h5.323s1.362-.072 1.457-1.494V16.61s.118-1.505-1.465-1.65c-.094-.008-4.984-.031-5.102-.024-1.536.096-1.583 1.47-1.583 1.47v2.46l-5.268 3.278v-5.84s.095-5.466 5.67-5.803h7.347s5.669.167 5.669 6.098v10.945s0 5.616-5.552 5.954h-7.119c-1.079 0-2.201-.087-3.196-.553-2.296-1.078-3.189-3.705-2.584-6.123.122-.49.302-.964.528-1.414.666-1.329 1.69-2.44 2.81-3.402 1.411-1.213 2.957-2.267 4.459-3.358"
      />
    </g>
    <rect width={19} height={16} x={32} fill="#fff" rx={2.316} />
    <path
      fill="#002B3A"
      d="m42.75 5.926-1.574 2.5L39.625 7.2 36.5 11.55h10zM48 3v10H35V3zm1-.998H34v11.996h15zM36.5 5.647a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0"
    />
    <defs>
      <clipPath id="great_spot_icon_image_clicked_svg__a">
        <path fill="#fff" d="M12.657 10.502h18.685v22.996H12.657z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgGreatSpotIconImageClicked;
