import { Waypoint } from '@omniafishing/core';
import React from 'react';
import SvgFishIconImageClicked from '../svg/fish_icon_image_clicked';
import SvgGreatSpotIconImageClicked from '../svg/great_spot_icon_image_clicked';
import SvgHazardIconImageClicked from '../svg/hazard_icon_image_clicked';
import SvgMarkerIconImageClicked from '../svg/marker_icon_image_clicked';
import SvgStructureIconImageClicked from '../svg/structure_icon_image_clicked';
import SvgWaypointFishIcon from '../svg/waypoint_fish_icon';
import SvgWaypointGreatSpotIcon from '../svg/waypoint_great_spot_icon';
import SvgWaypointHazardIcon from '../svg/waypoint_hazard_icon';
import SvgWaypointMarkerIcon from '../svg/waypoint_marker_icon';
import SvgWaypointStructureIcon from '../svg/waypoint_structure_icon';

export const WaypointMarker = ({
  waypoint_type,
  img,
}: {
  waypoint_type: Waypoint['waypoint_type'];
  img?: string;
}) => {
  if (img) {
    switch (waypoint_type) {
      case 'marker':
        return <SvgMarkerIconImageClicked width={28} />;
      case 'great_spot':
        return <SvgGreatSpotIconImageClicked width={28} />;
      case 'fish':
        return <SvgFishIconImageClicked width={28} />;
      case 'hazard':
        return <SvgHazardIconImageClicked width={28} />;
      case 'structure':
        return <SvgStructureIconImageClicked width={28} />;
      default:
        return null;
    }
  }
  switch (waypoint_type) {
    case 'marker':
      return <SvgWaypointMarkerIcon />;
    case 'great_spot':
      return <SvgWaypointGreatSpotIcon />;
    case 'fish':
      return <SvgWaypointFishIcon />;
    case 'hazard':
      return <SvgWaypointHazardIcon />;
    case 'structure':
      return <SvgWaypointStructureIcon />;
    default:
      return null;
  }
};
