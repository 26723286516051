import { AlgoliaProductFamily, ProductFamily } from '@omniafishing/core';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { productFamilyPriceRange } from '../../lib/product_families';
import { setShopifyImgWidth } from '../../lib/set_shopify_img_width';
import { OmniaUrls } from '../../lib/urls';
import { WebAnalytics } from '../../lib/web_analytics';
import styles from './search_product_family.less';

interface SearchProductProps {
  productFamily: AlgoliaProductFamily | ProductFamily;
  onClick: (target_url?: string) => void;
  position: number;
  list_uuid: string;
}

function isTypeAlgoliaProductFamily(
  value: AlgoliaProductFamily | ProductFamily
): value is AlgoliaProductFamily {
  return value.hasOwnProperty('_highlightResult');
}

export const SearchProductFamily = (props: SearchProductProps) => {
  const { productFamily, onClick, position, list_uuid } = props;

  const { handle, img_url } = productFamily;

  const title = isTypeAlgoliaProductFamily(productFamily)
    ? productFamily._highlightResult.title.value
    : productFamily.title;

  const colors = isTypeAlgoliaProductFamily(productFamily) ? productFamily.colors : [];

  // add prices
  // add sale price
  // show color count?

  return (
    <Link
      to={OmniaUrls.productFamily(handle)}
      className={
        'flex gap-1 border border-gray-300 rounded-md p-3 h-full justify-items-center flex-col sm:text-left hover:border-black text-black hover:text-black'
      }
      onClick={() => {
        onClick(OmniaUrls.productFamily(handle));
        WebAnalytics.productClick({
          productOrProductFamily: productFamily as ProductFamily,
          show_modal: false,
          position,
          list_uuid,
        });
      }}
    >
      <img
        src={setShopifyImgWidth(img_url, 200)}
        loading="lazy"
        width={150}
        height={150}
        className="ml-auto mr-auto"
      />
      <p
        className={classNames(styles.title, 'text-base')}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      {colors.length > 0 && (
        <p className="uppercase text-xs text-gray-600">{colors.length} colors</p>
      )}
      <p className="font-termina mt-auto">{productFamilyPriceRange(productFamily)}</p>
    </Link>
  );
};
