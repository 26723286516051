import type { SVGProps } from 'react';
import * as React from 'react';
const SvgHazardIconImageClicked = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={51}
    height={44}
    viewBox="0 0 51 30"
    fill="none"
    {...props}
  >
    <circle cx={22} cy={22} r={20.24} fill="#B91C1C" stroke="#fff" strokeWidth={3.52} />
    <g clipPath="url(#hazard_icon_image_clicked_svg__a)">
      <path
        fill="#fff"
        d="M21.935 7.078 9 30.792h25.87zm-1.078 8.623h2.156v7.546h-2.156zm1.078 12.127a1.348 1.348 0 1 1 0-2.696 1.348 1.348 0 0 1 0 2.696"
      />
    </g>
    <rect width={19} height={16} x={32} fill="#fff" rx={2.316} />
    <path
      fill="#002B3A"
      d="m42.75 5.926-1.574 2.5L39.625 7.2 36.5 11.55h10zM48 3v10H35V3zm1-.998H34v11.996h15zM36.5 5.647a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0"
    />
    <defs>
      <clipPath id="hazard_icon_image_clicked_svg__a">
        <path fill="#fff" d="M9 6h25.87v25.87H9z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgHazardIconImageClicked;
