import {
  AmbassadorMini,
  FishingReport,
  Season,
  SeasonGroup,
  SeasonsZone,
  Species,
  Structure,
  Technique,
  UserPrivate,
} from '@omniafishing/core';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import dayjs from 'dayjs';
import _ from 'lodash';
import { _getSeasonGroupBySeasonName } from '../redux/reference_data';
import { getDistanceBetweenTwoPoints } from './distance_between_two_points';

export const getFishingReportAttributes = (fishingReport: FishingReport) => {
  if (fishingReport == null) {
    return '';
  }

  const { season, species, technique } = fishingReport;
  return `${season.display_name} / ${species.display_name} / ${technique.display_name}`;
};

export const getFishingReportAttributesCopy = (fishingReport: FishingReport) => {
  if (fishingReport == null) {
    return '';
  }

  const { season, species, technique, forage } = fishingReport;
  return `${season.display_name} / ${species.display_name} / ${technique.display_name} / ${forage?.display_name}`;
};

export const rewardByUserRole = (user: AmbassadorMini | UserPrivate) => {
  if (!user) {
    return 0;
  }

  const { role } = user;
  if (role === 'ambassador_bronze' || role === 'ambassador_silver') {
    return 5;
  }
  if (role === 'ambassador_gold') {
    return 10;
  }
  return 0;
};

export const getSeasonGroupSpeciesTechniqueFishingReports = (
  fishingReports: FishingReport[],
  seasonGroup: SeasonGroup,
  species: Species,
  technique: Technique
) => {
  const seasonGroupSeasonNames = seasonGroup.seasons.map((s) => s.name);
  return fishingReports.filter(
    (report) =>
      seasonGroupSeasonNames.includes(report.season.name) &&
      report.species.name === species.name &&
      technique != null &&
      report.technique.name === technique.name
  );
};

export const getSeasonGroupSpeciesFishingReports = (
  fishingReports: FishingReport[],
  seasonGroup: SeasonGroup,
  species: Species
) => {
  if (!seasonGroup) {
    return [];
  }
  const seasonGroupSeasonNames = seasonGroup.seasons.map((s) => s.name);
  return fishingReports.filter(
    (report) =>
      seasonGroupSeasonNames.includes(report.season.name) && report.species.name === species.name
  );
};

export const getSeasonSpeciesFishingReports = (
  fishingReports: FishingReport[],
  season: Season,
  species: Species
) => {
  return fishingReports.filter(
    (report) => report.season.name === season.name && report.species.name === species.name
  );
};

export const getSeasonSpeciesTechniqueFishingReports = (
  fishingReports: FishingReport[],
  season: Season,
  species: Species,
  technique: Technique
) => {
  return fishingReports.filter(
    (report) =>
      report.season.name === season.name &&
      report.species.name === species.name &&
      technique != null &&
      report.technique.name === technique.name
  );
};

export const getSeasonNonSpeciesFishingReports = (
  fishingReports: FishingReport[],
  season: Season,
  species: Species
) => {
  const seasonReports = fishingReports.filter(
    (report) => report.season.name === season.name && report.species.name !== species.name
  );
  return _.sortBy(seasonReports, (report) => [
    report.species.display_order || Infinity,
    report.species.display_name,
  ]);
};

export const getSeasonGroupNonSpeciesFishingReports = (
  fishingReports: FishingReport[],
  seasonGroup: SeasonGroup,
  species: Species
) => {
  const seasonGroupSeasonNames = seasonGroup.seasons.map((s) => s.name);
  const seasonReports = fishingReports.filter(
    (report) =>
      seasonGroupSeasonNames.includes(report.season.name) && report.species.name !== species.name
  );
  return _.sortBy(seasonReports, (report) => [
    report.species.display_order || Infinity,
    report.species.display_name,
  ]);
};

export const getOtherSeasonFishingReports = (
  fishingReports: FishingReport[],
  season: Season,
  seasonZones: SeasonsZone[]
) => {
  const nonSeasonReports = fishingReports.filter((report) => report.season.name !== season.name);
  const selectedSeasonIndex = _.findIndex(
    seasonZones,
    (seasonZone) => seasonZone.season === season.name
  );
  const seasonsOrdered = [
    ...seasonZones.slice(selectedSeasonIndex),
    ...seasonZones.slice(0, selectedSeasonIndex),
  ].map((sz) => sz.season);

  return _.orderBy(nonSeasonReports, [
    (report) => {
      const idx = _.indexOf(seasonsOrdered, report.season.name);
      return idx > -1 ? idx : Infinity;
    },
    (report) => report.species.display_order || Infinity,
    (report) => report.species.display_name,
  ]);
};

// thanks for youtube shorts matt pocock
export const objectKeys = <Obj>(obj: Obj): (keyof Obj)[] => {
  return Object.keys(obj) as (keyof Obj)[];
};

interface FilterTypes {
  seasonGroup?: SeasonGroup;
  specie?: Species;
  species?: Species[];
  technique?: Technique;
  techniques?: Technique[];
  structure?: Structure;
  structures?: Structure[];
}

// TODO add product filtering support
export const getFilteredReports = (reports: FishingReport[], filters: FilterTypes) => {
  const { seasonGroup, specie, species, technique, techniques } = filters;

  // an array of filter names that are passed in that have a value
  const filterNames = objectKeys(filters).filter((key) => {
    const value = filters[key];
    return Array.isArray(value) ? value.length : filters[key] != null;
  });

  if (!filterNames.length || !reports.length) {
    return reports;
  }

  const filterTypes = {
    seasonGroup: (report: FishingReport) => fishingReportContainsSeasonGroup(report, seasonGroup),
    specie: (report: FishingReport) => fishingReportContainsSpecieName(report, specie.name),
    species: (report: FishingReport) =>
      fishingReportContainsSpeciesNames(
        report,
        species.map((s) => s.name)
      ),
    technique: (report: FishingReport) =>
      fishingReportContainsTechniqueName(report, technique.name),
    techniques: (report: FishingReport) =>
      fishingReportContainsTechniqueNames(
        report,
        techniques.map((t) => t.name)
      ),
    structure: (report: FishingReport) =>
      fishingReportContainsStructure(report, filters.structure.name),
    structures: (report: FishingReport) =>
      fishingReportContainsStructures(
        report,
        filters.structures.map((s) => s.name)
      ),
  };
  // this filter keeps the reports that satisfy every condition from filterNames[filterTypes]
  return reports.filter((report) => {
    return filterNames.every((filterName) => {
      const filter = filterTypes[filterName];
      return filter(report);
    });
  });
};

// confusing because on fishing reports the value is a season
// yet we always filter by seasonGroup
interface ReportFacets {
  season: Season;
  technique: Technique;
  species: Species;
  structure: Structure;
}

type ReportFacetName = keyof ReportFacets;
type ReportFacet = ReportFacets[ReportFacetName];

type FilterValues = Species | Technique | SeasonGroup | Structure; // this missing support for forage & product variant
type FacetWithCount = FilterValues & { report_count: number };

// TODO: update this to have clauses for product & media or make their own functions
export const getReportFacetOptions = (reports: FishingReport[], facet: ReportFacetName) => {
  return reports.reduce((acc, report) => {
    const facetValue = report[facet];
    if (!acc.some((f) => f.name === facetValue.name)) {
      acc.push(facetValue);
    }
    return acc;
  }, []);
};

function isSeason(type: ReportFacet): type is Season {
  return 'active' in type;
}

export function getReportOptionsAndCounts(
  reports: FishingReport[],
  reportFacetName: ReportFacetName,
  filters: FilterTypes,
  seasonGroupOptions?: SeasonGroup[]
): FacetWithCount[] {
  // must derive options separate from filtering reports otherwise options with no matches will be filtered out
  const options =
    reportFacetName === 'season'
      ? seasonGroupOptions
      : getReportFacetOptions(reports, reportFacetName);

  const optionsWithReportCount: FacetWithCount[] = options.map((f) => ({
    ...f,
    report_count: 0,
  }));

  const filteredFishingReports = getFilteredReports(reports, filters);

  filteredFishingReports.forEach((report) => {
    const reportValue = report[reportFacetName];
    let valueToIncrement: string;

    if (isSeason(reportValue)) {
      const season_group = _getSeasonGroupBySeasonName(seasonGroupOptions)(reportValue.name);
      valueToIncrement = season_group.name;
    } else {
      valueToIncrement = reportValue.name;
    }

    const index = optionsWithReportCount.findIndex((f) => f.name === valueToIncrement);
    if (index !== -1) {
      optionsWithReportCount[index].report_count++;
    }
  });
  return optionsWithReportCount;
}

export const fishingReportContainsWaterbodyId = (report: FishingReport, waterbodyId: string) => {
  return report.waterbody.id === waterbodyId;
};
export const fishingReportMatchesId = (report: FishingReport, reportId: number) => {
  return report.id === reportId;
};

export const fishingReportContainsSku = (fishingReport: FishingReport, variantSku: string) => {
  if (!variantSku) {
    return false;
  }
  if (fishingReport.featured_product?.sku === variantSku) {
    return true;
  }
  return fishingReport.products.some((p) => p.sku === variantSku);
};

export const fishingReportContainsSpecieName = (report: FishingReport, speciesName: string) => {
  return report.species.name === speciesName;
};

export const fishingReportContainsSpeciesNames = (report: FishingReport, species: string[]) => {
  if (species.length) {
    return species.includes(report.species.name);
  }
};

export const fishingReportContainsTechniqueName = (
  report: FishingReport,
  techniqueName: string
) => {
  return report.technique.name === techniqueName;
};

export const fishingReportContainsTechniqueNames = (
  report: FishingReport,
  techniques: CheckboxValueType[]
) => {
  if (techniques?.length) {
    return techniques.includes(report.technique.name);
  }
};

export const fishingReportContainsStructure = (report: FishingReport, structureName: string) => {
  return report.structure.name === structureName;
};

export const fishingReportContainsStructures = (
  report: FishingReport,
  structures: CheckboxValueType[]
) => {
  if (structures) {
    return structures.includes(report.structure.name);
  }
};

export const fishingReportContainsSeasonGroup = (
  report: FishingReport,
  seasonGroup: SeasonGroup
) => {
  const seasonNames = seasonGroup.seasons?.map((s) => s.name);
  return seasonNames?.includes(report.season.name);
};

export const fishingReportContainsMedia = (report: FishingReport, mediaName: 'video' | 'image') => {
  return mediaName === 'image' ? !!report.image : !!report.video;
};

export const fishingReportContainsMedias = (report: FishingReport, medias: CheckboxValueType[]) => {
  if (medias) {
    const image = report.image && 'image';
    const video = report.video && 'video';
    return medias.includes(image) || medias.includes(video);
  }
};

export const sortFishingReportsByDistance = (
  reports: FishingReport[],
  lat: number,
  lng: number
) => {
  if (lat == null || lng == null) {
    return reports;
  }
  return _.sortBy(reports, (fr) =>
    getDistanceBetweenTwoPoints({ lat, lng }, { lat: fr.waterbody.lat, lng: fr.waterbody.lng })
  );
};

export const fishingReportHasCommentsAndProducts = (report: FishingReport) => {
  return report.comments != null && (report.featured_product != null || report.products.length > 0);
};

export const fishingReportHasOutingDate = (report: FishingReport) => {
  return report.outing_date != null;
};

export const sortFishingReportsByCommentsProducts = (reports: FishingReport[]) => {
  return _.sortBy(reports, (report) => (fishingReportHasCommentsAndProducts(report) ? 0 : 1));
};

export const sortReportsByUserContentAndSeasonSpecies = _.memoize(
  (fishingReports: FishingReport[], seasonNames: string[], speciesName: string) => {
    if (!fishingReports.length) {
      return [];
    }

    const userContentSortedReports = sortFishingReportsByCommentsProducts(fishingReports);

    if (!seasonNames.length && !speciesName) {
      return userContentSortedReports;
    }

    return _.sortBy(userContentSortedReports, (fishingReport) => {
      const speciesMatch = fishingReport.species.name === speciesName;
      const seasonMatch = seasonNames.includes(fishingReport.season.name);
      if (speciesMatch && seasonMatch) {
        return 0;
      } else if (speciesMatch && !seasonMatch) {
        return 1;
      } else if (!speciesMatch && seasonMatch) {
        return 2;
      } else {
        return 3;
      }
    });
  },
  (fishingReports, seasonNames, speciesName) => {
    const reportsKey = fishingReports.map((r) => r.id).join('-');
    const seasonsKey = seasonNames.join('-');
    return `${reportsKey}-${seasonsKey}-${speciesName}`;
  }
);

export const sortReportsByUserContentAndSeasonOrSpecies = (
  fishingReports: FishingReport[],
  seasonGroup: SeasonGroup | null,
  species: Species | null
) => {
  if (!fishingReports.length) {
    return [];
  }

  const userContentSortedReports = sortFishingReportsByCommentsProducts(fishingReports);

  if (!seasonGroup && !species) {
    return userContentSortedReports;
  }

  return _.sortBy(userContentSortedReports, (fishingReport) => {
    const speciesMatch = species?.name === fishingReport.species.name;
    const seasonMatch = seasonGroup?.seasons.some((s) => s.name === fishingReport.season.name);

    if (speciesMatch && seasonMatch) {
      return 0;
    } else if (speciesMatch && !seasonMatch) {
      return 1;
    } else if (!speciesMatch && seasonMatch) {
      return 2;
    } else {
      return 3;
    }
  });
};

export const sortReportsByOutingDate = (frs: FishingReport[]) => {
  return _.orderBy(frs, ({ outing_date }) => outing_date || '', ['desc']);
};

export const sortReportsByOutingDateOrAcceptedAt = (reports: FishingReport[]) => {
  return _.orderBy(
    reports,
    [
      (fr) => {
        const { outing_date, accepted_at } = fr;

        const date = dayjs(outing_date || accepted_at);
        return date.startOf('day').toDate();
      },
    ],
    ['desc']
  );
};

export const filterReportsByStructuresTechniques = (args: {
  reports: FishingReport[];
  structures: string[];
  techniques: string[];
}) => {
  const { reports, structures, techniques } = args;
  return reports
    .filter((report) => {
      return (
        structures == null ||
        structures?.length === 0 ||
        structures?.includes(report.structure.name)
      );
    })
    .filter((report) => {
      return (
        techniques == null ||
        techniques?.length === 0 ||
        techniques?.includes(report.technique.name)
      );
    });
};

export const fishingReportContainsSeasonGroupSpecies = (args: {
  report: FishingReport;
  seasonGroup: SeasonGroup;
  species: Species;
}) => {
  const { report, seasonGroup, species } = args;

  return getSeasonGroupSpeciesFishingReports([report], seasonGroup, species).length > 0;
};

export const reportsWithinNumberOfDays = (fishingReports: FishingReport[], timePeriod: number) => {
  const daysAgo = dayjs().subtract(timePeriod, 'days');
  return fishingReports.filter((r) => dayjs(r.accepted_at).isAfter(daysAgo));
};

const filterFunctions = {
  species: (r: FishingReport, s: Species) => !s || fishingReportContainsSpecieName(r, s.name),
  season: (r: FishingReport, sg: SeasonGroup) => !sg || fishingReportContainsSeasonGroup(r, sg),
};

export const getOptionalSeasonGroupSpeciesReports = (
  fishingReports: FishingReport[],
  seasonGroup?: SeasonGroup,
  species?: Species
) => {
  return fishingReports.filter((fr) => {
    return filterFunctions.species(fr, species) && filterFunctions.season(fr, seasonGroup);
  });
};
