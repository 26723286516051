import type { SVGProps } from 'react';
import * as React from 'react';
const SvgFishIconImageClicked = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={51}
    height={44}
    fill="none"
    viewBox="0 0 51 30"
    {...props}
  >
    <circle cx={22} cy={22} r={20.24} fill="#1E40AF" stroke="#fff" strokeWidth={3.52} />
    <g clipPath="url(#fish_icon_image_clicked_svg__a)">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M31.636 20.857a1.078 1.078 0 1 0-2.156.001 1.078 1.078 0 0 0 2.156 0m3.234.524c-1.276 2.188-3.546 4.399-6.101 5.738-1.44-1.371-2.26-3.188-2.267-5.15-.008-2.07.886-3.992 2.476-5.415 2.438 1.063 4.59 2.81 5.892 4.827m-7.147 6.224a10.77 10.77 0 0 1-2.006.617c-1.208 1.454-3.013 2.336-5.698 2.336-.418 0-.856-.021-1.318-.065a5.6 5.6 0 0 0 1.1-2.86c-2.929-1.082-5.04-3.098-6.49-4.621-1.118 1.266-2.62 2.156-4.311 2.156 1.153-2.039 1.198-5.078 0-7.543 1.458.022 3.235.959 4.312 2.154 1.488-1.293 3.537-2.868 6.476-3.709-.108-.892-.43-1.798-1.087-2.694.463-.043.902-.064 1.32-.064 2.66 0 4.458.863 5.666 2.294.733.115 1.463.293 2.176.533-1.566 1.584-2.447 3.633-2.439 5.835.009 2.122.833 4.095 2.3 5.63"
        clipRule="evenodd"
      />
    </g>
    <rect width={19} height={16} x={32} fill="#fff" rx={2.316} />
    <path
      fill="#002B3A"
      d="m42.75 5.926-1.574 2.5L39.625 7.2 36.5 11.55h10zM48 3v10H35V3zm1-.998H34v11.996h15zM36.5 5.647a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0"
    />
    <defs>
      <clipPath id="fish_icon_image_clicked_svg__a">
        <path fill="#fff" d="M9 9h25.87v25.87H9z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgFishIconImageClicked;
