import { FishingReport } from '@omniafishing/core';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useFlickingRenderRef } from '../../hooks/use_flicking_render_ref';
import { useResponsive } from '../../hooks/use_responsive';
import OmniaCarousel from '../carousel/omnia_carousel';
import { DashboardFileReportCard } from '../dashboard/dashboard_file_report_card';
import { FishingReportCard } from '../fishing_report_card/fishing_report_card';
import { FishingReportProduct } from '../fishing_report_card/fishing_report_product';
import { OmniaButton } from '../omnia_button/omnia_button';
import styles from './fishing_reports_carousel.less';
import { ProductsModal } from './products_modal';

interface FishingReportsCarouselProps {
  fishingReports: FishingReport[];
  newReportIds: number[];
  showWaterbodyName?: boolean;
  showUserDetails?: boolean;
  showLocationInReportDetails?: boolean;
  showFileReportCard?: boolean;
  className?: string;
  adaptive?: boolean;
  userImageSize?: number;
}
export const FishingReportsCarousel = (props: FishingReportsCarouselProps) => {
  const {
    fishingReports,
    newReportIds,
    showWaterbodyName,
    showUserDetails,
    showFileReportCard,
    className,
    userImageSize,
  } = props;
  const { isDesktop, isMobile } = useResponsive();
  const [selectedReportId, setSelectedReportId] = useState<number | null>(null);

  const waterbody = fishingReports[0].waterbody;
  const displayFAFRCard = showFileReportCard && fishingReports.length > 0;
  const showOnDesktop = fishingReports.length > 4 && isDesktop;

  const { isRendered, renderCallbackRef } = useFlickingRenderRef();

  return (
    <div className={classNames(styles.carousel, className)} ref={renderCallbackRef}>
      <OmniaCarousel
        key={fishingReports.map((fr) => fr.id).join('') + isRendered}
        adaptive
        arrows
        newArrows
      >
        {fishingReports.map((report, i) => {
          const { featured_product, products } = report;
          const fishingReportProducts = [featured_product, ...products].filter(Boolean);
          const productToFeature = fishingReportProducts[0];
          const isHotbait = productToFeature?.sku === featured_product?.sku;
          const hasProducts = fishingReportProducts.length > 0;

          return (
            <li key={report.id} className={styles.cardContainer}>
              <FishingReportCard
                className={classNames(styles.card, styles.card__extraFooter)}
                fishingReport={report}
                heroContainerClassName={isMobile && styles.heroContainer}
                position={i}
                showLakeName={showWaterbodyName}
                showUserImage={showUserDetails}
                showUserName={showUserDetails}
                showUserStatus={showUserDetails}
                newBadge={newReportIds.includes(report.id)}
                userImageSize={userImageSize}
                mapPixelWidth={isMobile ? 330 : 370}
                mapPixelHeight={isMobile ? 263 : 327}
                mapClassName={styles.map}
                reportProductInsteadOfCarousel={
                  hasProducts && (
                    <div className={styles.productCardContainer}>
                      <FishingReportProduct
                        product={productToFeature}
                        position={null}
                        isHotbait={isHotbait}
                        fishingReport={report}
                        imgContainerClassName={styles.productCardImageContainer}
                        buttonSize={isMobile ? 'sm' : 'md'}
                        className={styles.productCard}
                      />
                    </div>
                  )
                }
                extraFooter={
                  hasProducts && (
                    <div
                      className={styles.footer}
                      onClick={() => {
                        if (hasProducts) {
                          setSelectedReportId(report.id);
                        }
                      }}
                    >
                      <OmniaButton
                        size="md"
                        kind="text"
                        onClick={() => setSelectedReportId(report.id)}
                      >
                        View All Products
                      </OmniaButton>
                    </div>
                  )
                }
              />
              {selectedReportId === report.id && (
                <ProductsModal
                  open={selectedReportId === report.id}
                  onModalChange={() => setSelectedReportId(null)}
                  report={report}
                  position={i}
                />
              )}
            </li>
          );
        })}
        {displayFAFRCard && (showOnDesktop || isMobile) && (
          <li className={styles.card}>
            <DashboardFileReportCard className={styles.fileReportCard} waterbody={waterbody} />
          </li>
        )}
      </OmniaCarousel>
    </div>
  );
};
