import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  InfoCircleOutlined,
  LockOutlined,
} from '@ant-design/icons';
import { FishingReport } from '@omniafishing/core';
import { Divider, Tooltip } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useResponsive } from '../../hooks/use_responsive';
import { apiV1 } from '../../lib/api';
import { toDollars } from '../../lib/to_dollars';
import { OmniaUrls } from '../../lib/urls';
import { roleAsText } from '../../lib/user';
import { FishingReportModalActions } from '../../redux/fishing_report_modal';
import base from '../../styles/base.less';
import { FishingReportCardComments } from '../fishing_report_card_legacy/fishing_report_card_comments';
import { FishingReportCardDetails } from '../fishing_report_card_legacy/fishing_report_card_details';
import { FishingReportCardMiniProducts } from '../fishing_report_card_legacy/fishing_report_card_mini_products';
import { OmniaButton } from '../omnia_button/omnia_button';
import styles from './dashboard_user_report.less';
import { UserFishingReportCard } from './user_fishing_report_card';
import { UserReportCardMedia } from './user_report_card_media';

dayjs.extend(advancedFormat);

interface DashboardUserReportProps {
  fishingReport: FishingReport;
  position: number;
  onDeleteClick: () => void;
}

export const DashboardUserReport = (props: DashboardUserReportProps) => {
  const { fishingReport, position, onDeleteClick } = props;
  const dispatch = useDispatch();
  const { isMobile, isDesktop } = useResponsive();
  const {
    user,
    converted,
    converted_amount_cents,
    converted_max_cents,
    waterbody,
    created_at,
    locked,
    accepted,
    featured_product,
    image,
    video,
    comments,
    products,
  } = fishingReport;

  const [isExpanded, setIsExpanded] = useState(false);
  const cardRef = useRef(null);
  const initialCardHeight = cardRef.current && `${cardRef.current.scrollHeight}px`;

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const eligibleAmount = converted_max_cents / 100;
  const earnedAmount = converted_amount_cents / 100;
  const remainingAmount = eligibleAmount - earnedAmount;
  const partiallyConverted =
    converted_amount_cents > 0 && converted_amount_cents < converted_max_cents;

  let reportStatusTag: React.ReactNode;

  if (converted) {
    reportStatusTag = <span className={styles.orangeText}>Fully Converted. Great Report!</span>;
  } else if (partiallyConverted) {
    reportStatusTag = <span className={styles.orangeText}>Partially Converted</span>;
  } else if (accepted) {
    reportStatusTag = <span>No Conversions</span>;
  } else if (!accepted) {
    reportStatusTag = <span>Pending Approval</span>;
  }

  const infoTooltipContent = converted
    ? 'You have earned the maximum amount eligible for this fishing report. File more fishing reports to continue earning tackle credits.'
    : converted_amount_cents === 0
    ? `Each report that you file is eligible for earning Omnia store credits when someone buys tackle based on your recommendations in this report. As a ${roleAsText(
        user.role
      )} level ambassador, you can earn up to ${toDollars(
        eligibleAmount
      )} for each of your reports.`
    : `You have earned ${toDollars(
        earnedAmount
      )} in tackle credit for this fishing report. You can earn an additional ${toDollars(
        remainingAmount
      )} when another customer buys tackle based on your recommendations in this report.`;

  const editFishingReport = async () => {
    const {
      data: { data: waterbodyDetail },
    } = await apiV1.waterbodyFetch(waterbody.url_slug);
    dispatch(FishingReportModalActions.FISHING_REPORT_MODAL_OPEN(waterbodyDetail, fishingReport));
  };

  const listProductsWithFeaturedProduct = [featured_product, ...products].filter(Boolean);

  const hasProducts = listProductsWithFeaturedProduct.length > 0;

  const sharedProps = {
    fishingReport,
    position,
  };

  let userShouldAddComments = !comments;
  let userShouldAddMedia = !image && !video;

  //  Add Media & Add Comments are supported on desktop
  // only Add Media is supported on mobile

  if (userShouldAddMedia) {
    userShouldAddComments = false;
    userShouldAddMedia = true;
  } else if (!userShouldAddMedia && userShouldAddComments) {
    userShouldAddComments = true;
    userShouldAddMedia = false;
  }

  return (
    <li>
      <section
        className={classNames(styles.fishingReportRow, { [styles.mobileCard]: isMobile })}
        key={fishingReport.id}
        ref={cardRef}
        style={
          isMobile
            ? isExpanded
              ? { height: initialCardHeight }
              : { height: '468px' }
            : { height: 'auto' }
        }
      >
        {isDesktop ? (
          <>
            <div className={styles.fishingReportSidebar}>
              <div className={styles.statsSection}>
                <p className={styles.statsHeader}>
                  <Link to={OmniaUrls.waterbody(waterbody)}>{waterbody.primary_name} </Link>
                  <i>Filed on {dayjs(created_at).format('MMM Do YYYY')}</i>
                </p>
                <div className={styles.datesSection}>
                  {/* need to add updated_at to FishingReport Type */}
                  {/* <p>
                    Last Updated: <span>{dayjs(updated_at).format('MM/DD/YY')}</span>
                  </p> */}
                  <p>Report Status: {reportStatusTag}</p>
                  {/* <p>
                    Views Count: <span> {views}</span>
                  </p> */}
                </div>
                <div className={styles.creditsSection}>
                  <div className={styles.creditCol}>
                    <p>Eligible Credits</p>
                    <span> {toDollars(eligibleAmount)}</span>
                  </div>
                  <Divider type="vertical" style={{ height: 'unset' }}></Divider>
                  <div className={styles.creditCol}>
                    <p>
                      Earned Credits{' '}
                      <Tooltip title={infoTooltipContent} placement={isMobile ? 'top' : 'topRight'}>
                        <InfoCircleOutlined style={{ fontSize: '12px' }} />
                      </Tooltip>
                    </p>
                    <span>{toDollars(earnedAmount)}</span>
                  </div>
                </div>
              </div>
              <div className={styles.lowerStatsSection}>
                <div className={styles.reportActionButtons}>
                  <OmniaButton
                    size="lg"
                    kind="primary"
                    isDisabled={locked}
                    onPress={editFishingReport}
                  >
                    {locked ? (
                      <>
                        <LockOutlined /> Locked
                      </>
                    ) : (
                      <>
                        <EditOutlined /> Edit Report
                      </>
                    )}
                  </OmniaButton>
                  <OmniaButton size="lg" kind="tertiary" onPress={onDeleteClick}>
                    <DeleteOutlined style={{ minWidth: '12px' }} /> Delete Report
                  </OmniaButton>
                </div>
              </div>
            </div>
            <UserFishingReportCard
              fishingReport={fishingReport}
              position={position}
              className={styles.fishingReportCard}
            />
          </>
        ) : (
          <>
            <div className={styles.fishingReportSidebar}>
              <div className={styles.statsSection}>
                <div className={styles.statsHeader__mobile}>
                  <p>{waterbody.primary_name}</p>
                  <span>Filed: {dayjs(created_at).format('MM/DD/YY')}</span>
                </div>
                <UserReportCardMedia
                  image={image}
                  video={video}
                  fishingReport={fishingReport}
                  position={position}
                  userShouldAddMedia={userShouldAddMedia}
                />
              </div>
              <div className={styles.creditsSection}>
                <div className={styles.creditCol}>
                  <p>Eligible Credits</p>
                  <span> {toDollars(eligibleAmount)}</span>
                </div>
                <Divider type="vertical" style={{ height: 'unset' }}></Divider>
                <div className={styles.creditCol}>
                  <p>
                    Earned Credits{' '}
                    <Tooltip title={infoTooltipContent} placement={isMobile ? 'top' : 'topRight'}>
                      <InfoCircleOutlined style={{ fontSize: '12px' }} />
                    </Tooltip>
                  </p>
                  <span>{toDollars(earnedAmount)}</span>
                </div>
              </div>
            </div>
            <div className={styles.mobileDetailsSection}>
              <div
                className={classNames({
                  [styles.mobileDetailsSection__collapsed]: !isExpanded,
                })}
              ></div>
              <FishingReportCardDetails
                {...sharedProps}
                isLakePage={false}
                cardSize={'small'}
                showLakeName={false}
              />
              <section className={styles.rowTwo}>
                <FishingReportCardComments
                  {...sharedProps}
                  isLakePage={false}
                  defaultExpanded={false}
                />
              </section>
              <section className={styles.productsCarouselRow}>
                {hasProducts && (
                  <div
                    className={classNames(styles.mobileProductsSectionDetails, {
                      [styles.invisible]: !isExpanded,
                    })}
                  >
                    <FishingReportCardMiniProducts
                      featured_product={featured_product}
                      products={listProductsWithFeaturedProduct}
                      {...sharedProps}
                    />
                  </div>
                )}
              </section>
              <div
                className={classNames(styles.creditsAndActionsSection, {
                  [styles.invisible]: !isExpanded,
                })}
              >
                <div className={styles.reportActionButtons}>
                  <OmniaButton
                    size="lg"
                    kind="primary"
                    isDisabled={locked}
                    onPress={editFishingReport}
                  >
                    {locked ? (
                      <>
                        <LockOutlined /> Locked
                      </>
                    ) : (
                      <>
                        <EditOutlined /> Edit Report
                      </>
                    )}
                  </OmniaButton>
                  <OmniaButton size="lg" kind="tertiary" onClick={onDeleteClick}>
                    <DeleteOutlined style={{ minWidth: '12px' }} /> Delete Report
                  </OmniaButton>
                </div>
              </div>
            </div>
          </>
        )}
      </section>
      {isMobile && (
        <div className={styles.mobileCardFooter} onClick={handleExpandClick}>
          <span className={base.link}>
            {isExpanded ? 'Hide Details' : 'View Details'}
            <DownOutlined
              className={classNames(
                { [styles.rotatedArrow]: isExpanded },
                { [styles.rotatedArrow__reverse]: !isExpanded }
              )}
              width={7}
              height={9}
            />
          </span>
        </div>
      )}
    </li>
  );
};
