import {
  FishingReport,
  Media,
  ProductFamily,
  ShopifyProduct,
  ShopifyVariant,
} from '@omniafishing/core';
import { Divider } from 'antd';
import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ProductDetailImages } from '../../components/product_detail_images/product_detail_images';
import { FishingReportsWidget } from '../../components/product_variant_selector/fishing_reports_widget';
import { ProductTitle } from '../../components/product_variant_selector/product_title';
import {
  ColorOption,
  ProductVariantSelector,
} from '../../components/product_variant_selector/product_variant_selector';
import { ReportRecommendationBadge } from '../../components/product_variant_selector/report_recommendation_badge';
import { isDone } from '../../constants/loading_state';
import { useResponsive } from '../../hooks/use_responsive';
import { useUser } from '../../hooks/use_user';
import { fishingReportContainsSku } from '../../lib/fishing_reports';
import { WebAnalytics } from '../../lib/web_analytics';
import { getIpState } from '../../redux/geographic_location';
import { getFishingReportsLoadingState } from '../../redux/product_detail';
import { getUserWaterbodies } from '../../redux/user';
import { productFamilyIsBait } from './product_detail_page';
import styles from './product_detail_upper_section.less';
import { ProBanner } from './pro_banner';

interface ProductDetailUpperSection {
  fishingReports: FishingReport[];
  media: Media[];
  nearbyFishingReports: FishingReport[];
  onProductSkuChange: (productSku: string) => void;
  onSpeciesNameChange: (speciesName: string) => void;
  onTechniqueNameChange: (technique: string[]) => void;
  openAndScrollToFishingReportsPanel: () => void;
  openAndScrollToProductGridPanel: () => void;
  productFamily: ProductFamily;
  selectedVariant: ShopifyVariant;
  shopifyProduct: ShopifyProduct;
}

export const ProductDetailUpperSection = (props: ProductDetailUpperSection) => {
  const {
    fishingReports,
    media,
    nearbyFishingReports,
    onProductSkuChange,
    onSpeciesNameChange,
    onTechniqueNameChange,
    openAndScrollToFishingReportsPanel,
    openAndScrollToProductGridPanel,
    productFamily,
    selectedVariant,
    shopifyProduct,
  } = props;

  const { isDesktop, isMobile } = useResponsive();
  const { isPro } = useUser();
  const [hoveredColor, setHoveredColor] = useState<ColorOption>(null);
  const productFamilyFishingReportsLoadingState = useSelector(getFishingReportsLoadingState);
  const ipState = useSelector(getIpState);
  const userWaterbodies = useSelector(getUserWaterbodies);

  const selectedProduct = useMemo(() => {
    return selectedVariant && productFamily
      ? productFamily.products.find((familyProduct) => familyProduct.sku === selectedVariant.sku)
      : null;
  }, [selectedVariant, productFamily]);

  const selectedProductFishingReports = useMemo(() => {
    return fishingReports.filter((report) =>
      fishingReportContainsSku(report, selectedProduct?.sku)
    );
  }, [fishingReports, selectedProduct?.sku]);

  const fishingReportsInIpState = useMemo(() => {
    return selectedProduct
      ? selectedProductFishingReports.filter((f) =>
          f.waterbody.locales.map((l) => l.state.abbr).includes(ipState)
        )
      : [];
  }, [selectedProductFishingReports, ipState]);

  const userWaterbodiesFishingReports = useMemo(() => {
    const userWaterbodyIds = userWaterbodies.map((w) => w.id);
    return selectedProductFishingReports.filter((r) => userWaterbodyIds.includes(r.waterbody.id));
  }, [selectedProductFishingReports, userWaterbodies]);

  const hasFishingReportsInIpState = fishingReportsInIpState.length > 0;

  const hasFishingReportsNearby = nearbyFishingReports.length > 0;
  const hasUserWaterbodyReports = userWaterbodiesFishingReports.length > 0;

  const hasReportRecommendation =
    hasUserWaterbodyReports || hasFishingReportsNearby || hasFishingReportsInIpState;

  const handleSkuChange = useCallback(
    (productSku: string) => {
      onProductSkuChange(productSku);
      openAndScrollToFishingReportsPanel();
      WebAnalytics.productDetailPageClick('[stats].(top_variation)');
    },
    [onProductSkuChange, openAndScrollToFishingReportsPanel]
  );

  const handleSpeciesNameChange = useCallback(
    (speciesName: string) => {
      onSpeciesNameChange(speciesName);
      openAndScrollToFishingReportsPanel();
      WebAnalytics.productDetailPageClick('[stats].(top_species)');
    },
    [onSpeciesNameChange, openAndScrollToFishingReportsPanel]
  );

  const handleTechniqueNameChange = useCallback(
    (technique: string[]) => {
      onTechniqueNameChange(technique);
      openAndScrollToFishingReportsPanel();
      WebAnalytics.productDetailPageClick('[stats].(top_technique)');
    },
    [onTechniqueNameChange, openAndScrollToFishingReportsPanel]
  );

  const handleViewAllReportsClick = useCallback(() => {
    WebAnalytics.productDetailPageClick('[stats].(view_all)');
    openAndScrollToFishingReportsPanel();
  }, [openAndScrollToFishingReportsPanel]);

  return (
    <div className={styles.sectionWrapper}>
      <div className={styles.heroContainer}>
        <div className={styles.sticky}>
          <ProductDetailImages
            hoveredColor={hoveredColor}
            media={media}
            productFamily={productFamily}
            selectedVariant={selectedVariant}
            shopifyProduct={shopifyProduct}
          />
        </div>
      </div>
      <div className={styles.titleContainer}>
        <ProductTitle
          productFamily={productFamily}
          selectedProduct={selectedProduct}
          selectedVariant={selectedVariant}
          shopifyProduct={shopifyProduct}
        />
        <div
          className={classNames({
            [styles.widgetsWrapper]: hasReportRecommendation || fishingReports.length > 0,
          })}
        >
          {fishingReports.length > 0 && (
            <FishingReportsWidget
              buttonClassName={styles.fishingReportsWidgetButton}
              fishingReports={fishingReports}
              hideTechniquesSection={productFamilyIsBait(productFamily)}
              onProductSkuChange={handleSkuChange}
              onSpeciesNameChange={handleSpeciesNameChange}
              onTechniqueNameChange={handleTechniqueNameChange}
              onViewAllReportsClick={handleViewAllReportsClick}
              placement={isMobile ? 'bottomLeft' : 'bottom'}
              products={productFamily.products}
            />
          )}
          {isDone(productFamilyFishingReportsLoadingState) && hasReportRecommendation && (
            <ReportRecommendationBadge
              className={styles.reportBadgeWidgetContainer}
              nearbyFishingReports={nearbyFishingReports}
              openAndScrollToFishingReportsPanel={openAndScrollToFishingReportsPanel}
              selectedProductFishingReports={selectedProductFishingReports}
              showIcon
              userWaterbodiesFishingReports={userWaterbodiesFishingReports}
            />
          )}
        </div>
        {isDesktop && !isPro && (
          <div className="mt-3">
            <ProBanner />
          </div>
        )}
        {isDesktop && <Divider />}
      </div>
      <div className={styles.selectorContainer}>
        <ProductVariantSelector
          openProductGrid={openAndScrollToProductGridPanel}
          onColorHover={setHoveredColor}
          selectedProduct={selectedProduct}
          selectedVariant={selectedVariant}
          shopifyProduct={shopifyProduct}
          productFamily={productFamily}
        />
      </div>
      {isMobile && !isPro && (
        <div className="mt-10">
          <ProBanner />
        </div>
      )}
    </div>
  );
};
