import * as React from 'react';
const SvgMarkerIconImageClicked = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={51}
    height={44}
    viewBox="0 0 51 30"
    fill="none"
    {...props}
  >
    <circle cx={22} cy={22} r={20.24} fill="#10B981" stroke="#fff" strokeWidth={3.52} />
    <path
      fill="#fff"
      d="M21.935 17.261a2.81 2.81 0 0 0-2 .829 2.819 2.819 0 0 0-.83 2c0 .756.296 1.466.83 2.002a2.822 2.822 0 0 0 2 .828 2.82 2.82 0 0 0 2.001-.828 2.81 2.81 0 0 0 .829-2.001 2.81 2.81 0 0 0-.829-2.001 2.81 2.81 0 0 0-2-.829m8.655-.957a9.158 9.158 0 0 0-2.018-2.923 9.37 9.37 0 0 0-2.986-1.966 9.476 9.476 0 0 0-3.65-.722 9.52 9.52 0 0 0-3.651.72 9.41 9.41 0 0 0-2.987 1.965 9.187 9.187 0 0 0-2.018 2.923 8.973 8.973 0 0 0-.743 3.588c0 1.783.427 3.56 1.266 5.272.674 1.377 1.617 2.719 2.804 3.994 2.029 2.178 4.156 3.51 4.76 3.866a1.109 1.109 0 0 0 1.131 0c.604-.357 2.732-1.688 4.76-3.866 1.188-1.273 2.13-2.617 2.804-3.994.844-1.71 1.271-3.484 1.271-5.27 0-1.243-.25-2.45-.743-3.587m-8.655 8.233a4.446 4.446 0 1 1 0-8.893 4.446 4.446 0 0 1 0 8.893"
    />
    <rect width={19} height={16} x={32} fill="#fff" rx={2.316} />
    <path
      fill="#002B3A"
      d="m42.75 5.926-1.574 2.5L39.625 7.2 36.5 11.55h10zM48 3v10H35V3zm1-.998H34v11.996h15zM36.5 5.647a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0"
    />
  </svg>
);
export default SvgMarkerIconImageClicked;
