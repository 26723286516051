import type { SVGProps } from 'react';
import * as React from 'react';
const SvgStructureIconImageClicked = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={51}
    height={44}
    viewBox="0 0 51 30"
    fill="none"
    {...props}
  >
    <circle cx={22} cy={22} r={20.24} fill="#78350F" stroke="#fff" strokeWidth={3.52} />
    <g clipPath="url(#structure_icon_image_clicked_svg__a)">
      <path
        fill="#fff"
        d="m18.54 26.326-5.498-6.01-3.211 1.406-.835-1.909 4.577-2.003 4.967 5.429 5.21-5.695 3.913 4.039 3.916-2.849 3.295 2.547-1.274 1.65-2.059-1.592-4.115 2.993-3.633-3.749z"
      />
    </g>
    <rect width={19} height={16} x={32} fill="#fff" rx={2.316} />
    <path
      fill="#002B3A"
      d="m42.75 5.926-1.574 2.5L39.625 7.2 36.5 11.55h10zM48 3v10H35V3zm1-.998H34v11.996h15zM36.5 5.647a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0"
    />
    <defs>
      <clipPath id="structure_icon_image_clicked_svg__a">
        <path fill="#fff" d="M9 9h25.87v25.87H9z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgStructureIconImageClicked;
