import {
  AlgoliaProductFamily,
  Product,
  ProductFamily,
  RecommendedProductFamily,
} from '@omniafishing/core';
import { getProductFamilyOptionsFromProducts } from '../components/product_family_quick_modal/quick_select_variant_selector_helpers';
import { toDollars } from './to_dollars';

export const productFamiliesByCategory = (productFamilies: ProductFamily[], category: string) => {
  return productFamilies.filter((pf) => pf.category === category);
};

export const getFamiliesByCategory = (families: RecommendedProductFamily[], category: string) => {
  return families.filter(
    (recommendedFamily) => recommendedFamily.product_family.category === category
  );
};

export const productFamilyColorOptions = (productFamily: ProductFamily, products: Product[]) => {
  const productFamilyOptions = getProductFamilyOptionsFromProducts(productFamily, products);
  const colorOption = productFamilyOptions.find((option) => option.name.toLowerCase() === 'color');

  if (!colorOption) {
    return [];
  }

  return colorOption.values.map((value) => {
    return {
      name: value.value,
      src: value.src,
    };
  });
};

export const productFamilyPriceRange = (
  product_family: ProductFamily | AlgoliaProductFamily,
  asDollars = true
) => {
  const { price_max, price_min } = product_family;
  const min = asDollars ? toDollars(price_min) : price_min;
  const max = asDollars ? toDollars(price_max) : price_max;

  if (min === max) {
    return `${min}`;
  } else {
    return `${min} – ${max}`;
  }
};
