import { CalendarOutlined } from '@ant-design/icons';
import { FishingReport } from '@omniafishing/core';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useListAttributionUuid } from '../../hooks/use_list_attribution_uuid';
import { useResponsive } from '../../hooks/use_responsive';
import { WebAnalytics } from '../../lib/web_analytics';
import { getSeasonGroupBySeasonName } from '../../redux/reference_data';
import { ProductCarousel } from '../carousel/product_carousel';
import { FishingReportCardComments } from '../fishing_report_card_legacy/fishing_report_card_comments';
import { FishingReportCardMedia } from '../fishing_report_card_legacy/fishing_report_card_media/fishing_report_card_media';
import { UserDetails } from '../fishing_report_card_legacy/user_details';
import { ProductCardMini } from '../product_card_mini/product_card_mini';
import SvgThermometerAir from '../svg/thermometer_air';
import styles from './user_fishing_report_card.less';

interface UserFishingReportCardProps {
  className?: string;
  fishingReport: FishingReport;
  position: number;
}

export const UserFishingReportCard = (props: UserFishingReportCardProps) => {
  const { className, fishingReport, position } = props;
  const { featured_product, image, video, comments, products } = fishingReport;
  const seasonGroupBySeasonName = useSelector(getSeasonGroupBySeasonName);
  const list_uuid = useListAttributionUuid();
  const { isSmallDesktop } = useResponsive();
  const { outing_date, water_temperature, season, species, structure, style, forage } =
    fishingReport;

  const listProductsWithFeaturedProduct = [featured_product, ...products].filter(Boolean);
  const hasProducts = listProductsWithFeaturedProduct.length > 0;

  // TODO: add UI for userShouldAddProducts
  let userShouldAddComments = !comments;
  let userShouldAddProducts = !products.length && !featured_product;
  let userShouldAddMedia = !image && !video;

  // Add Media & Add Comments are supported on desktop, Add Media on mobile
  if (userShouldAddProducts) {
    userShouldAddComments = false;
    userShouldAddMedia = false;
    userShouldAddProducts = true;
  } else if (!userShouldAddProducts && userShouldAddMedia) {
    userShouldAddComments = false;
    userShouldAddMedia = true;
    userShouldAddProducts = false;
  } else if (!userShouldAddProducts && !userShouldAddMedia && userShouldAddComments) {
    userShouldAddComments = true;
    userShouldAddMedia = false;
    userShouldAddProducts = false;
  }

  const formattedOutingDate = dayjs(outing_date).format('M/D/YY');

  const hasFeaturedProduct = fishingReport.featured_product != null;

  const handleProductClick = useCallback(
    (isHotbait: boolean) => {
      WebAnalytics.fishingReportEmbeddedClick(
        isHotbait ? '[reports].(hotbait)' : '[reports].(products)',
        fishingReport,
        'embedded',
        position
      );
    },
    [fishingReport, list_uuid, position]
  );

  const sharedProps = {
    fishingReport,
    position,
  };

  return (
    <>
      <section className={classNames(styles.fishingReportCard, className)}>
        <section className={styles.reportDetails}>
          {isSmallDesktop ? (
            <>
              <div className={styles.reportDetailsColumnOne}>
                <UserDetails
                  fishingReport={fishingReport}
                  position={position}
                  className={styles.userDetails}
                  showLakeName={false}
                />
                <div className={styles.subDetails}>
                  {outing_date && (
                    <div className={styles.outingDate}>
                      <p>
                        <CalendarOutlined /> {formattedOutingDate}
                      </p>
                    </div>
                  )}
                  {water_temperature && (
                    <div className={styles.waterTemp}>
                      <p>
                        <span className={styles.thermIcon}>
                          <SvgThermometerAir />
                        </span>{' '}
                        {water_temperature}° (Water)
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.reportDetailsColumnTwo}>
                <ul className={styles.details}>
                  <li>
                    <p className={styles.detailsCategory}>Season</p>
                    <p className={styles.detailsDetail}>
                      <span
                        className={classNames(
                          styles.seasonIcon,
                          styles[seasonGroupBySeasonName(season.name).name]
                        )}
                      ></span>
                      {season.display_name}
                    </p>
                  </li>
                  <li className={styles.species}>
                    <p className={styles.detailsCategory}>Species</p>
                    <p className={styles.detailsDetail}>{species.display_name}</p>
                  </li>
                  <li>
                    <p className={styles.detailsCategory}>Technique</p>
                    <p className={styles.detailsDetail}>{style.display_name}</p>
                  </li>
                  <li>
                    <p className={styles.detailsCategory}>Structure</p>
                    <p className={styles.detailsDetail}>{structure.display_name}</p>
                  </li>
                  {forage && (
                    <li>
                      <p className={styles.detailsCategory}>Forage</p>
                      <p className={styles.detailsDetail}>{forage.display_name}</p>
                    </li>
                  )}
                </ul>
              </div>
            </>
          ) : (
            <div className={styles.reportDetailsColumnTwo}>
              <ul className={styles.details}>
                {outing_date && (
                  <li className={styles.outingDateMobile}>
                    <p className={styles.detailsCategory}>Outing Date</p>
                    <p className={styles.detailsDetail}>
                      <CalendarOutlined style={{ marginRight: '5px' }} /> {formattedOutingDate}
                    </p>
                  </li>
                )}
                <li className={classNames({ [styles.seasonLabel__noOutingDate]: !outing_date })}>
                  <p className={styles.detailsCategory}>Season</p>
                  <p className={styles.detailsDetail}>
                    <span
                      className={classNames(
                        styles.seasonIcon,
                        styles[seasonGroupBySeasonName(season.name).name]
                      )}
                    ></span>
                    {season.display_name}
                  </p>
                </li>
                <li className={styles.species}>
                  <p className={styles.detailsCategory}>Species</p>
                  <p className={styles.detailsDetail}>
                    {species.image && <img src={species.image} />}
                    {species.display_name}
                  </p>
                </li>
                <li className={styles.technique}>
                  <p className={styles.detailsCategory}>Technique</p>
                  <p className={styles.detailsDetail}>{style.display_name}</p>
                </li>
                <li>
                  <p className={styles.detailsCategory}>Structure</p>
                  <p className={styles.detailsDetail}>{structure.display_name}</p>
                </li>
                {forage && (
                  <li>
                    <p className={styles.detailsCategory}>Forage</p>
                    <p className={styles.detailsDetail}>{forage.display_name}</p>
                  </li>
                )}
                {water_temperature && (
                  <li>
                    <p className={styles.detailsCategory}>Water Temperature</p>
                    <p className={classNames(styles.detailsDetail, styles.waterTempMobile)}>
                      <span className={styles.thermIcon}>
                        <SvgThermometerAir />
                      </span>{' '}
                      {water_temperature}° (Water)
                    </p>
                  </li>
                )}
              </ul>
            </div>
          )}
        </section>
        <section className={styles.rowTwo}>
          <FishingReportCardComments
            {...sharedProps}
            isLakePage={false}
            defaultExpanded={false}
            userShouldAddComments={userShouldAddComments}
          />
          <FishingReportCardMedia
            {...sharedProps}
            userShouldAddMedia={userShouldAddMedia}
            image={image}
            video={video}
            showLakeName={false}
          />
        </section>
        {hasProducts && (
          <section className={styles.productsCarouselRow}>
            <ProductCarousel
              products={listProductsWithFeaturedProduct}
              key={listProductsWithFeaturedProduct.map((product) => product.sku).join()}
              list_id_title="Products Associated With This Report"
              list_id_type="report"
              arrows={true}
              list_uuid={list_uuid}
              fadedEdges
              resizeOnContentsReady
            >
              {listProductsWithFeaturedProduct.map((product, i) => {
                const isHotbait = hasFeaturedProduct && i === 0;
                return (
                  <div key={`${product.sku}_${isHotbait}`} className={styles.productCard}>
                    <ProductCardMini
                      product={product}
                      isHotbait={isHotbait}
                      handleProductClick={handleProductClick}
                      list_uuid={list_uuid}
                      productPosition={i}
                    />
                  </div>
                );
              })}
            </ProductCarousel>
          </section>
        )}
      </section>
    </>
  );
};
