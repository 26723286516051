import {
  AlgoliaBrand,
  AlgoliaProductFamily,
  AlgoliaWaterbodyDetail,
  AmbassadorMini,
  Media,
  ProductFamily,
} from '@omniafishing/core';
import { Input, InputRef, Modal } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';
import React, {
  MutableRefObject,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import searchInsights from 'search-insights';
import useDynamicRefs from 'use-dynamic-refs';
import { AlgoliaSearchEvents, searchClient } from '../../algolia';
import { getCategoryBySubcategoryUrl } from '../../categories_subcategories';
import { isPending, LoadingState } from '../../constants/loading_state';
import { getEnv } from '../../env';
import { useQueryString } from '../../hooks/use_query_string';
import { useUserPreferences } from '../../hooks/use_user_preferences';
import { apiV1 } from '../../lib/api';
import { GlobalQueryParams } from '../../lib/query_string';
import { getTabHashByIndex, getTabIndexByHash, TabData } from '../../lib/tabs';
import { SearchItemType, WebAnalytics } from '../../lib/web_analytics';
import { hasAccessToken } from '../../redux/auth';
import { getCategories, getStates, getSubcategories } from '../../redux/reference_data';
import { getUser, getUserWaterbodies } from '../../redux/user';
import { getIsMobile } from '../../redux/window';
import { RoutePaths } from '../../routes';
import base from '../../styles/base.less';
import tabs from '../../styles/tabs.less';
import { ContentWrapper } from '../content_wrapper/content_wrapper';
import Loading from '../loading/loading';
import SvgSearch from '../svg/search';
import { SearchBrand } from './search_brand';
import { SearchProductFamilyDefaultResultsList } from './search_product_family_default_results_list';
import { SearchProductFamilyResultsList } from './search_product_family_results_list';
import styles from './search_universal.less';
import { SearchWaterbody } from './search_waterbody';

export enum SearchQueryParams {
  search_tab = 'search_tab',
  search_open = 'search_open',
}

const SEARCH_RESULTS_MAX = 1000;
const env = getEnv();

export enum TabHashes {
  top = 'top',
  products = 'products',
  lakes = 'lakes',
  ambassadors = 'ambassadors',
  articles_videos = 'articles_videos',
}

export interface SearchDefaults {
  product_families: ProductFamily[];
  ambassadors: AmbassadorMini[];
  media: Media[];
}

export const SearchUniversal = ({
  searchModalVisibleRef,
}: {
  searchModalVisibleRef: MutableRefObject<boolean>;
}) => {
  const initialState = {
    brands: {
      results: [] as AlgoliaBrand[],
      totalResults: 0,
      lastQueryId: null as string,
    },
    productFamilies: {
      results: [] as AlgoliaProductFamily[],
      totalResults: 0,
      lastQueryId: null as string,
    },
    waterbodies: {
      results: [] as AlgoliaWaterbodyDetail[],
      totalResults: 0,
      lastQueryId: null as string,
    },
  };
  const [state, setState] = useState(initialState);
  const user = useSelector(getUser);
  const isMobile = useSelector(getIsMobile);
  const subcategories = useSelector(getSubcategories);
  const categories = useSelector(getCategories);
  const location = useLocation();
  const states = useSelector(getStates);
  const userWaterbodies = useSelector(getUserWaterbodies);
  const isLoggedIn = useSelector(hasAccessToken);
  const { userPreferencesWaterbodies } = useUserPreferences();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [loadingState, setLoadingState] = useState(LoadingState.NOT_STARTED);
  const queryStringFetchPending = isPending(loadingState);
  const fetchRef = useRef(0);
  const inputRef = useRef<InputRef>(null);
  const [getRef, setRef] = useDynamicRefs();
  const scrolledToTabRef = useRef(false);
  const defaultResultsSeenRef = useRef(false);

  const { getCurrentQuery, replaceQueryString } = useQueryString();
  const queryString = getCurrentQuery<{
    [GlobalQueryParams.query]: string;
    [SearchQueryParams.search_tab]: string;
    [SearchQueryParams.search_open]: boolean;
  }>();

  const currentQueryString = queryString.query;
  const searchOpenParam = queryString.search_open;
  const isSearchQueryParamOpen = searchOpenParam === true;
  const selectedTabQueryParam = queryString.search_tab;

  const [modalOpen, setModalOpen] = useState(false);
  const [stringToQuery, setStringToQuery] = useState('');
  // close modal
  useEffect(() => {
    setModalOpen(false);

    // cleanup function runs on dependency change not just unmount
    return () => {
      searchModalVisibleRef.current = false;
      scrolledToTabRef.current = false;
    };
  }, [location.pathname]);

  // open modal
  useEffect(() => {
    // searchModalVisibleRef must be used b/c this component currently 01/23 renders twice on site load
    const modalIsClosed = searchModalVisibleRef.current === false;
    if (currentQueryString && modalIsClosed && isSearchQueryParamOpen) {
      setLoadingState(LoadingState.PENDING);
      algoliaSearch(currentQueryString);
      setModalOpen(true);
      searchModalVisibleRef.current = true;
      replaceQueryString({
        [SearchQueryParams.search_open]: true,
      });
      setTimeout(() => inputRef.current.focus(), 1);
    }
  }, [currentQueryString]);

  // tab syncing and scroll to tab once search fetch is complete
  useEffect(() => {
    const tabHashes = tabsContent.map((tab) => tab.hash);
    const searchTabStringMatch = tabHashes.includes(selectedTabQueryParam);
    const tabIndex = tabHashes.indexOf(selectedTabQueryParam);
    const shouldScrollToTab = scrolledToTabRef.current === false;

    if (searchTabStringMatch && shouldScrollToTab) {
      setSelectedTabIndex(tabIndex);

      if (isMobile) {
        const wrapper = (getRef('search_wrapper') as RefObject<HTMLDivElement>)?.current;
        const tabHeading = (
          getRef(`search_${selectedTabQueryParam}`) as RefObject<HTMLHeadingElement>
        )?.current;

        if (wrapper && tabHeading) {
          wrapper.scrollTo({
            left: tabHeading.getBoundingClientRect().left + wrapper.scrollLeft - 22,
            behavior: 'smooth',
          });
          scrolledToTabRef.current = true;
        }
      }
    }
    // state change here is what confirms a fetch is complete and runs the effect
  }, [selectedTabQueryParam, state, selectedTabIndex]);

  // clear tab if query string is empty
  useEffect(() => {
    if (currentQueryString === '' && selectedTabIndex !== 0 && selectedTabQueryParam !== 'top') {
      setSelectedTabIndex(0);
      replaceQueryString({
        [SearchQueryParams.search_tab]: 'top',
      });
    }
  }, [currentQueryString]);

  // fetch search defaults
  useEffect(() => {
    // could add a ref to limit this to one fetch if we want to limit API call
    apiV1.searchDefaultsFetch().then((response) => {
      const { product_families, ambassadors, media } = response.data.data;
      setDefaults({
        product_families,
        ambassadors,
        media,
      });
    });
  }, []);

  const algoliaSearch = useCallback(
    (val: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      const commonParams = {
        hitsPerPage: SEARCH_RESULTS_MAX,
        clickAnalytics: true,
        enablePersonalization: true,
        userToken: user?.id?.toString(),
        getRankingInfo: true,
      };
      const commonIndexes = [
        env.ALGOLIA_INDEX_BRANDS,
        env.ALGOLIA_INDEX_PRODUCT_FAMILIES,
        env.ALGOLIA_INDEX_WATERBODIES,
      ];

      const queries = [
        ...commonIndexes.map((index) => {
          return {
            indexName: index,
            query: val,
            params: {
              ...commonParams,
            },
          };
        }),
      ];

      // TODO: add personalization tracking
      searchClient.multipleQueries(queries).then((response) => {
        if (fetchId !== fetchRef.current) {
          // another search has happened, these are not the latest results
          return;
        }
        const { results } = response;
        setLoadingState(LoadingState.DONE);

        // results order follows queries order
        const [brandsResults, productFamiliesResults, waterbodiesResults] = results;

        setState({
          brands: {
            results: brandsResults.hits as AlgoliaBrand[],
            totalResults: brandsResults.nbHits,
            lastQueryId: brandsResults.queryID,
          },
          waterbodies: {
            results: waterbodiesResults.hits as AlgoliaWaterbodyDetail[],
            totalResults: waterbodiesResults.nbHits,
            lastQueryId: waterbodiesResults.queryID,
          },
          productFamilies: {
            results: productFamiliesResults.hits as AlgoliaProductFamily[],
            totalResults: productFamiliesResults.nbHits,
            lastQueryId: productFamiliesResults.queryID,
          },
        });

        // TODO set tab to "top" if selected tab has no results
      });
    },
    [fetchRef, user]
  );

  const debouncedAlgoliaSearch = useCallback(
    _.debounce(
      (val) => {
        algoliaSearch(val);
      },
      200,
      {
        leading: false,
        trailing: true,
      }
    ),
    [fetchRef, user, algoliaSearch]
  );

  const onSearchClick = useCallback(() => {
    if (currentQueryString?.length > 0) {
      setLoadingState(LoadingState.PENDING);
      algoliaSearch(currentQueryString);
    }
    setModalOpen(true);
    searchModalVisibleRef.current = true;
    replaceQueryString({
      [SearchQueryParams.search_open]: true,
    });
    setTimeout(() => inputRef.current.focus(), 0);
    // tab setting occurs once fetch is complete aka state updates
    // is handled in the "tab" useEffect

    WebAnalytics.topNavClick({
      click_key: '[top_nav].(search)',
      logged_in: isLoggedIn,
    });
  }, [currentQueryString, searchModalVisibleRef, inputRef, algoliaSearch, isLoggedIn]);

  const handleQueryChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      setStringToQuery(val);

      if (val.length > 0) {
        debouncedAlgoliaSearch(val);
      }
    },
    [debouncedAlgoliaSearch]
  );

  const initialLoadRef = useRef(true);
  useEffect(() => {
    if (initialLoadRef.current) {
      initialLoadRef.current = false;
      return;
    }
    debouncedReplaceQueryString({
      [GlobalQueryParams.query]: stringToQuery,
    });
  }, [stringToQuery]);

  useEffect(() => {
    if (isSearchQueryParamOpen) {
      if (currentQueryString && stringToQuery === '') {
        setStringToQuery(currentQueryString);
      }

      if (!currentQueryString && stringToQuery !== '') {
        setStringToQuery('');
      }
    }
  }, [currentQueryString, isSearchQueryParamOpen]);

  const debouncedReplaceQueryString = useCallback(
    _.debounce((params) => {
      replaceQueryString(params);
    }, 300),
    [currentQueryString, selectedTabQueryParam, searchOpenParam]
  );

  const onTabSelect = (index: number) => {
    const tabHash = getTabHashByIndex(tabsContent, index);
    setSelectedTabIndex(index);
    replaceQueryString({
      [SearchQueryParams.search_tab]: tabHash,
    });
  };
  const setTabIndexByHash = (hash: TabHashes) => () => {
    setSelectedTabIndex(getTabIndexByHash(tabsContent, hash));
    replaceQueryString({
      [SearchQueryParams.search_tab]: hash,
    });
  };

  const TOP_RESULTS_COUNT = isMobile ? 3 : 5;

  const [defaults, setDefaults] = useState<SearchDefaults>({
    product_families: [],
    ambassadors: [],
    media: [],
  });

  const productSubcategoryNames = state.productFamilies.results.map((p) => p.subcategory);
  const productSubcategories = _.sortBy(
    subcategories.filter((s) => productSubcategoryNames.includes(s.name)),
    'display_name'
  );

  const waterbodyStateAbbrs = _.flatten(
    state.waterbodies.results.map((w) => w.locales.map((l) => l.state.abbr.toLowerCase()))
  ).sort();
  const waterbodyStates = states.filter((s) => waterbodyStateAbbrs.includes(s.abbr.toLowerCase()));

  const hasProducts = state.productFamilies.results.length > 0;
  const hasWaterbodies = state.waterbodies.results.length > 0;
  const hasBrands = state.brands.results.length > 0;

  const webAnalyticsSearchResultsHelper = (type: SearchItemType, target_url: string) => {
    WebAnalytics.searchResultClick(currentQueryString, type, target_url);
  };

  const tabsContent: TabData[] = [
    {
      title: `Top Results`,
      hash: TabHashes.top,
      panel: (
        <>
          {hasProducts && (
            <>
              <div className={styles.topResultHeading}>
                <h3 className={styles.sectionHeading}>
                  Products ({state.productFamilies.totalResults})
                </h3>
                {state.productFamilies.totalResults > TOP_RESULTS_COUNT && (
                  <span className={base.link} onClick={setTabIndexByHash(TabHashes.products)}>
                    See all ›
                  </span>
                )}
              </div>
              <SearchProductFamilyResultsList
                isFocused={selectedTabIndex === 0}
                productFamilies={state.productFamilies.results.slice(0, TOP_RESULTS_COUNT)}
                currentQueryString={currentQueryString}
                lastQueryId={state.productFamilies.lastQueryId}
                getRankingInfo
                allResultsCount={state.productFamilies.totalResults}
                onSeeAllClick={setTabIndexByHash(TabHashes.products)}
              />
            </>
          )}

          {hasBrands && (
            <>
              <div className={styles.topResultHeading}>
                <h3 className={styles.sectionHeading}>Brands ({state.brands.totalResults})</h3>
                {state.brands.totalResults > TOP_RESULTS_COUNT && (
                  <span className={base.link} onClick={setTabIndexByHash(TabHashes.products)}>
                    See all ›
                  </span>
                )}
              </div>
              <ul className={classNames(styles.list, styles.list__brands)}>
                {state.brands.results.slice(0, TOP_RESULTS_COUNT).map((b, index) => (
                  <li key={b.url_slug}>
                    <SearchBrand
                      brand={b}
                      onClick={(target_url: string) => {
                        if (state.brands.lastQueryId) {
                          searchInsights(AlgoliaSearchEvents.clickedObjectIDsAfterSearch, {
                            index: env.ALGOLIA_INDEX_BRANDS,
                            eventName: 'search_bar_brand_clicked',
                            queryID: state.brands.lastQueryId,
                            objectIDs: [currentQueryString],
                            positions: [index + 1],
                            userToken: user?.id?.toString(),
                          });
                        }
                        webAnalyticsSearchResultsHelper('brand', target_url);
                      }}
                    />
                  </li>
                ))}
              </ul>
            </>
          )}

          {hasWaterbodies && (
            <>
              <div className={styles.topResultHeading}>
                <h3 className={styles.sectionHeading}>Lakes ({state.waterbodies.totalResults})</h3>
                {state.waterbodies.totalResults > TOP_RESULTS_COUNT && (
                  <span className={base.link} onClick={setTabIndexByHash(TabHashes.lakes)}>
                    See all ›
                  </span>
                )}
              </div>
              <ul className={styles.list}>
                {state.waterbodies.results.slice(0, TOP_RESULTS_COUNT).map((w, index) => (
                  <li key={w.waterbody_id}>
                    <SearchWaterbody
                      waterbody={w}
                      onClick={(target_url: string) => {
                        if (state.waterbodies.lastQueryId) {
                          searchInsights(AlgoliaSearchEvents.clickedObjectIDsAfterSearch, {
                            index: env.ALGOLIA_INDEX_WATERBODIES,
                            eventName: 'search_bar_waterbody_clicked',
                            queryID: state.waterbodies.lastQueryId,
                            objectIDs: [currentQueryString],
                            positions: [index + 1],
                            userToken: user?.id?.toString(),
                          });
                        }
                        webAnalyticsSearchResultsHelper('waterbody', target_url);
                      }}
                    />
                  </li>
                ))}
              </ul>
            </>
          )}
        </>
      ),
    },
  ];

  if (hasProducts) {
    tabsContent.push({
      title: `Products (${state.productFamilies.totalResults})`,
      hash: TabHashes.products,
      panel: (
        <>
          {hasBrands && (
            <>
              <h3 className={styles.sectionHeading}>Brands</h3>
              <ul className={classNames(styles.list, styles.list__wrap, styles.list__brands)}>
                {state.brands.results.map((b, index) => (
                  <li key={b.url_slug}>
                    <SearchBrand
                      brand={b}
                      onClick={(target_url: string) => {
                        if (state.brands.lastQueryId) {
                          searchInsights(AlgoliaSearchEvents.clickedObjectIDsAfterSearch, {
                            index: env.ALGOLIA_INDEX_BRANDS,
                            eventName: 'search_bar_brand_clicked',
                            queryID: state.brands.lastQueryId,
                            objectIDs: [currentQueryString],
                            positions: [index + 1],
                            userToken: user?.id?.toString(),
                          });
                        }
                        webAnalyticsSearchResultsHelper('brand', target_url);
                      }}
                    />
                  </li>
                ))}
              </ul>
            </>
          )}

          <h3 className={styles.sectionHeading}>Products</h3>
          <SearchProductFamilyResultsList
            isFocused={selectedTabIndex === 1}
            productFamilies={state.productFamilies.results}
            currentQueryString={currentQueryString}
            lastQueryId={state.productFamilies.lastQueryId}
          />

          <h3 className={styles.sectionHeading}>Categories</h3>
          <ul
            className={classNames(
              styles.list,
              styles.list__wrap,
              styles.list__text,
              styles.list__subcategories
            )}
          >
            {productSubcategories.map((subcategory) => {
              const category = getCategoryBySubcategoryUrl(categories, subcategory.url_path);
              return (
                <li key={subcategory.name}>
                  <Link
                    to={`${RoutePaths.CATEGORIES}/${category.url_path}/${subcategory.url_path}`}
                  >
                    {subcategory.display_name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </>
      ),
    });
  }

  if (hasWaterbodies) {
    tabsContent.push({
      title: `Lakes (${state.waterbodies.totalResults})`,
      hash: TabHashes.lakes,
      panel: (
        <>
          <h3 className={styles.sectionHeading}>States</h3>
          <ul className={classNames(styles.list, styles.list__wrap, styles.list__text)}>
            {waterbodyStates.map((s) => (
              <li key={s.abbr}>
                <Link to={`${RoutePaths.STATES}/${s.slug}`} className={styles.link}>
                  {s.name}
                </Link>
              </li>
            ))}
          </ul>

          <h3 className={styles.sectionHeading}>Lakes</h3>
          <ul className={classNames(styles.list, styles.list__wrap)}>
            {state.waterbodies.results.map((w, index) => (
              <li key={w.waterbody_id}>
                <SearchWaterbody
                  waterbody={w}
                  onClick={(target_url: string) => {
                    if (state.waterbodies.lastQueryId) {
                      searchInsights(AlgoliaSearchEvents.clickedObjectIDsAfterSearch, {
                        index: env.ALGOLIA_INDEX_WATERBODIES,
                        eventName: 'search_bar_waterbody_clicked',
                        queryID: state.waterbodies.lastQueryId,
                        objectIDs: [currentQueryString],
                        positions: [index + 1],
                        userToken: user?.id?.toString(),
                      });
                    }
                    webAnalyticsSearchResultsHelper('waterbody', target_url);
                  }}
                />
              </li>
            ))}
          </ul>
        </>
      ),
    });
  }

  const defaultWaterbodies = userWaterbodies.length ? userWaterbodies : userPreferencesWaterbodies;

  const onSubmit = () => {
    setSelectedTabIndex(getTabIndexByHash(tabsContent, TabHashes.products));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.iconWrapper} onClick={onSearchClick}>
        <span className={styles.iconText}>Search Site</span>{' '}
        <SvgSearch className={styles.icon} width={20} height={20} />
      </div>

      <Modal
        open={modalOpen}
        onCancel={() => {
          setModalOpen(false);
          replaceQueryString({
            [SearchQueryParams.search_open]: false,
          });
          scrolledToTabRef.current = false;
        }}
        centered
        style={{ top: 20 }}
        width={'100%'}
        wrapClassName={styles.searchModalWrapper}
        zIndex={10000}
      >
        <div className={styles.searchWrapper}>
          <p className={styles.categoryHeader}>Search</p>
          <Input
            className={styles.input}
            onChange={handleQueryChange}
            onPressEnter={onSubmit}
            placeholder="Search for gear, lakes, articles, videos, people..."
            ref={inputRef}
            value={stringToQuery}
            suffix={<SvgSearch onClick={onSearchClick} />}
            allowClear
            defaultValue={currentQueryString}
          />
        </div>

        {currentQueryString ? (
          <>
            {queryStringFetchPending ? (
              <div className={styles.loadingWrapper}>
                <Loading text="Loading Search Results" />
              </div>
            ) : (
              <Tabs
                selectedTabClassName={tabs.tab__active}
                selectedTabPanelClassName={tabs.tabPanel__active}
                disabledTabClassName={tabs.tab__disabled}
                className={tabs.tabs__left}
                selectedIndex={selectedTabIndex}
                onSelect={onTabSelect}
                forceRenderTabPanel
              >
                <TabList className={tabs.tabs__searchUniversal}>
                  <ContentWrapper
                    className={styles.tabs__contentWrapper}
                    ref={setRef('search_wrapper') as RefObject<HTMLHeadingElement>}
                  >
                    {tabsContent.map((tab) => {
                      return (
                        <Tab
                          className={tabs.tab}
                          key={tab.hash}
                          onClick={() => {
                            WebAnalytics.searchResultFilter(
                              currentQueryString,
                              tab.hash as TabHashes
                            );
                          }}
                        >
                          <h3
                            className={tabs.tabHeading}
                            ref={setRef(`search_${tab.hash}`) as RefObject<HTMLHeadingElement>}
                          >
                            {tab.title}
                          </h3>
                        </Tab>
                      );
                    })}
                  </ContentWrapper>
                </TabList>

                <ContentWrapper className={styles.tabContent}>
                  {tabsContent.map((tab) => (
                    <TabPanel className={tabs.tabPanel} key={tab.hash}>
                      {tab.panel}
                    </TabPanel>
                  ))}
                </ContentWrapper>
              </Tabs>
            )}
          </>
        ) : (
          <ContentWrapper>
            <p className={styles.categoryHeader}>Suggested</p>
            <p className={styles.subCategoryHeader}>Popular Products</p>
            <SearchProductFamilyDefaultResultsList
              productFamilies={defaults.product_families.slice(0, TOP_RESULTS_COUNT)}
              listViewedRef={defaultResultsSeenRef}
              lastQueryId={state.productFamilies.lastQueryId}
              currentQueryString={currentQueryString}
            />

            {defaultWaterbodies.length > 0 && (
              <>
                <p className={styles.subCategoryHeader}>
                  {userWaterbodies.length ? 'Your Lakes' : 'Your Recent Lakes'}
                </p>
                <ul className={styles.list}>
                  {defaultWaterbodies.slice(0, TOP_RESULTS_COUNT).map((w, index) => (
                    <li key={index}>
                      <SearchWaterbody
                        waterbody={w}
                        onClick={(target_url: string) => {
                          if (state.waterbodies.lastQueryId) {
                            searchInsights(AlgoliaSearchEvents.clickedObjectIDsAfterSearch, {
                              index: env.ALGOLIA_INDEX_WATERBODIES,
                              eventName: 'search_bar_waterbody_clicked',
                              queryID: state.waterbodies.lastQueryId,
                              objectIDs: [currentQueryString],
                              positions: [index + 1],
                              userToken: user?.id?.toString(),
                            });
                          }
                          webAnalyticsSearchResultsHelper('waterbody', target_url);
                        }}
                      />
                    </li>
                  ))}
                </ul>
              </>
            )}
          </ContentWrapper>
        )}
      </Modal>
    </div>
  );
};
